
/* Général */
$body_background_color : #28b292;
$body_texte_color: #000; 
$link_color : #000; 
$link_hover_color : #1fa36c;

$titlesection_color : #fff;
$titlesection_background_color : #1fa36c;
$titlesection_before_bordercolor : transparent #1fa36c transparent transparent;
$titlesection_after_bordercolor : transparent transparent transparent #1fa36c;

/* Header */
$header_background_color : #fff;
$slogan_color : #000;

$nav_color : #fff;
$nav_background_color : #000;
$nav_a_before_bordercolor : transparent black transparent transparent;
$nav_a_after_bordercolor : transparent transparent transparent black;
$sousnav_color: #fff;
$sousnav_background_color : #1fa36c;
$sousnav_hover_color : #1fa36c;
$sousnav_hover_background_color : #fff;
$nav_actif_color : #fff; 
$nav_actif_background_color : #1fa36c;
$nav_actif_before_bordercolor : transparent #1fa36c transparent transparent;
$nav_actif_after_bordercolor : transparent transparent transparent #1fa36c;

/* Content */
$content_background_color : #fff;
$navarianne_borderbottom_color : #000;
$navarianne_borderbottom_hover_color : #1fa36c;
$navarianne_actif_color : #1fa36c;
$navarianne_actif_background_color : #fff;
$navarianne_actif_bordercolor : #1fa36c;
$button_background_color : #1fa36c;
$button_color : #fff;
$button_border_color: #1fa36c;
$button_hover_color : #1fa36c;
$button_hover_background_color : #fff;

$formperso_titre_color: #1fa36c;


/* accueil */
$slidetext_color: #000;
$slidetext_background_color : #fff;


/* boutique */
$etiquette_color : #fff;
$etiquette_background_color : #4BCBAE;
$produits_mask_color : #fff;
$produits_mask_background_color : rgba(40,178,146,0.6);
$produits_zoom_color : #fff;
$produits_zoom_background_color : #1fa36c;
$produits_zoom_hover_color : #1fa36c;
$produits_zoom_hover_background_color : #fff;
$oldprix_color : #8c8c8c;
$prix_color: #1fa36c;

$ficheproduit_triangleremise_bordercolor: #1fa36c;
$ficheproduit_oldprix_color: #8c8c8c;
$ficheproduit_fleches_color: #1fa36c;

$searchbaricon_color : #fff;
$searchbaricon_background_color : #1fa36c;


/* galerie */
$photo_mask_background_color : rgb(31, 163, 108);
$photo_description_color : #fff;
$photo_description_background_color : #fff;
$photo_commentaires_color : #fff;

/* livre d'or */
$msglo_texte_color: #000; 
$msglo_h4_color: #000;
$msglo_h4_background_color: #4BCBAE;
$msglo_note_color: #fff;

/* contact */
$form_focus_shadow: 0 0 5px #28b292;
$form_focus_bordercolor: #28b292;


/* Footer */
$footer_color : #fff;
$footer_background_color : rgb(34, 34, 34); 

/* Blog */
$background_blog_menu :  #1fa36c;
$background_blog_h2 : #1fa36c;
$white : #fff;

/* Panier template 2 */
$panier_background_color_2:#1fa36c;
$panier_texte_color_2:#fff;
/* Template Panier */
$panier-background-color:#fff;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/732series/declinaison";  